import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { version } from '../../package.json'
import { CopyIcon, ShareIcon } from '../icons'

export function Footer(props) {
  const { addToast } = useToasts()

  const share = async () => {
    const shareData = {
      title: document.title,
      text: '',
      url: window.location.href,
    }

    if (window && window.navigator) {
      try {
        await navigator.share(shareData)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const copy = () => {
    if (window && window.navigator) {
      window.navigator.clipboard.writeText(window.location.href)
      const toast = 'Basariyla kopyalandi!'
      addToast(toast, { appearance: 'info', autoDismiss: true })
    }
  }

  return (
    <footer id="footer" className="pb-3 bg-light footer">
      <div className="container">
        <p className="m-0 text-center">
          Online Dua v{version} <br />
          <small className="cursor-pointer" onClick={share}>
            Paylaş <ShareIcon />
          </small>{' '}
          -{' '}
          <small className="cursor-pointer" onClick={copy}>
            Adresi Kopyala <CopyIcon />
          </small>
        </p>
        <div className="text-center">
          <small>
            <a href="/sikca-sorulan-sorular">Sıkça Sorulan Sorular</a>{' '}
            <a rel="noopener noreferrer" className="ml-3" target="_blank" href="https://twitter.com/onlineduaorg">
              @onlineduaorg
            </a>
            <a
              className="ml-3"
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hasenat.dev@gmail.com?subject=OnlineDua.org%20%7C%20Feedback&body=%0D%0ACihaz%3A%0D%0ABrowser%2FTarayici%3A%0D%0A"
            >
              İletişim
            </a>
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
