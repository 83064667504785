import React from "react";

function Error() {
  return (
    <div className="container my-4 min-height">
      <div className="container">
        <h1>Hata...</h1>
        <h4>Malesef böyle bir sayfa bulunamadi..</h4>
      </div>
    </div>
  );
}

export default Error;
