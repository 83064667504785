import Axios from 'axios'
import lockr from 'lockr'
import moment from 'moment'
import slugify from 'slugify'
import { updateBookmark } from './store/reducers'

export const getBookmarks = (props) => {
  if (lockr.get('token')) {
    Axios.defaults.headers['Authorization'] = lockr.get('token')
    Axios.get(`${process.env.REACT_APP_SERVER}/api/bookmark`)
      .then(({ data }) => {
        props.dispatch(updateBookmark(data))
        if (data.marked) {
          lockr.set('marked', data.marked)
        }
        if (data.lastPage) {
          setLocalStorage('lastPage', data.lastPage)
        }
        if (data.cevsenMarked) {
          setLocalStorage('cevsenMarked', data.cevsenMarked)
        }
        if (data.localStorage) {
          setLocalStorageValuesFromServer(data.localStorage)
        }
      })
      .catch(() => {
        let marked = lockr.get('marked')
        if (marked) {
          props.dispatch(updateBookmark({ marked }))
        }
        let lastPage = lockr.get('lastPage')
        if (lastPage) {
          props.dispatch(updateBookmark({ lastPage }))
        }
        let cevsenMarked = lockr.get('cevsenMarked')
        if (cevsenMarked) {
          props.dispatch(updateBookmark({ cevsenMarked }))
        }
      })
  } else {
    let marked = lockr.get('marked')
    if (marked) {
      props.dispatch(updateBookmark({ marked }))
    }
    let lastPage = lockr.get('lastPage')
    if (lastPage) {
      props.dispatch(updateBookmark({ lastPage }))
    }
    let cevsenMarked = lockr.get('cevsenMarked')
    if (cevsenMarked) {
      props.dispatch(updateBookmark({ cevsenMarked }))
    }
  }
}

export const getlocale = (date) => {
  moment.locale('tr')
  return date ? moment(date).fromNow() : ''
}

export const renderTimer = (secs) => {
  var min, sec
  min = Math.round(secs / 60)
  if (min < 10) min = `0${min}`
  sec = secs % 60
  if (sec < 10) sec = `0${sec}`

  return `${min} : ${sec}`
}

export const reloadPage = () => {
  window.location.reload()
}

export const filterAndGet = (kelime, data) => {
  if (kelime) {
    return data.filter((part) => {
      return slugify(String(part.name).toLowerCase()).includes(slugify(kelime.toLowerCase()))
    })
  } else {
    return data
  }
}

export const postLocalStorageChanges = async () => {
  if (lockr.get('token')) {
    await Axios.post(`${process.env.REACT_APP_SERVER}/api/localStorage`, lockr.getAll(true))
  }
}

export const setLocalStorage = async (name, value) => {
  lockr.set(name, value)
  const lastPush = sessionStorage.getItem('lastPush') || 0
  const now = String(Date.now()).slice(0, 10)
  const mustPush = !keysToExclude.includes(name)
  if (lastPush < now && mustPush) {
    sessionStorage.setItem('lastPush', String(Number(now) + 1))
    postLocalStorageChanges()
  }
}

export const keysToExclude = ['localStorage', 'token', 'marked', 'lastPage', 'cevsenMarked', 'celceKalinan', 'risaleMarked', 'stats']
export const setLocalStorageValuesFromServer = (data) => {
  data.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (!keysToExclude.includes(key)) {
        const value = obj[key]
        if (lockr.get(key) !== value) {
          lockr.set(key, value)
        }
      }
    })
  })
}

export const arabFontStyleName = 'arabFontStyle'
export const arabFontStyleDefault = { fontSize: 36, lineHeight: 31 }
