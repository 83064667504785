import React, { useEffect, useState } from 'react'
import { NavLink as Link, withRouter } from 'react-router-dom'
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap'
import lockr from 'lockr'
import Axios from 'axios'
import { connect } from 'react-redux'
import { updateFont, updateSaved } from '../store/reducers'
import { getBookmarks, setLocalStorage } from '../utils'
import GenericModal from '../GenericModal/GenericModal'
import EmailSave from '../PasswordChange/EmailSave'

const newvers = 'ver-405'
const oldvers = 'ver-404'
const lastDate = '11.05.2022'

function NavComponent(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState({})
  const [isModalOpened, setIsModalOpened] = useState(new Date(lastDate).getTime() < Date.now() ? true : lockr.get(newvers))

  useEffect(() => {
    let token = lockr.get('token')
    lockr.rm(oldvers)
    if (token) {
      setState({ ...state, isAuth: true })
      Axios.defaults.headers['Authorization'] = token
    }

    if (lockr.get('darkMode')) {
      document.getElementById('body').className = 'dark-body'
    }

    getBookmarks(props)
    let font = lockr.get('font')
    if (font) {
      props.dispatch(updateFont(font))
    } else {
      props.dispatch(updateFont('textBased'))
    }

    if (token) {
      Axios.get(`${process.env.REACT_APP_SERVER}/api/saved`)
        .then((saved) => {
          props.dispatch(updateSaved(saved.data))
        })
        .catch((err) => {
          let saved = lockr.get('saved')
          if (saved) {
            props.dispatch(updateSaved(saved))
          }
        })
    } else {
      let saved = lockr.get('saved')
      if (saved) {
        props.dispatch(updateSaved(saved))
      }
    }
    // eslint-disable-next-line
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const logout = () => {
    lockr.rm('token')
    window.location.href = '/'
  }

  const closeModal = () => {
    setIsModalOpened(true)
    setLocalStorage(newvers, true)
  }

  let { user } = props

  const duyuru = (
    <GenericModal isOpen={!isModalOpened} onCloseModal={closeModal}>
      <div className="px-3 py-2 overflow-auto">
        <h5 className="text-center">Yeni Özellikler</h5>
        <small>
          <h6>Versiyon 4.0.5</h6>
          <ul>
            <li>Kulubu'd Daria ve Meali için gece okuma modu eklendi.</li>
            <li>Kronometre artık sayfa açılır açılmaz çalışacak. Resetlemek için üzerine 2 defa tıklamanız yeterli.</li>
            <li>Birkaç hata giderildi.</li>
          </ul>
          <hr />
        </small>
        <small>
          <h6>Versiyon 4.0.4</h6>
          <ul>
            <li>
              Artık sadece kaldığınız yerler değil, gece/gündüz modu, eklediğiniz zikirler, hatimler ve geri kalan her şey üye hesabı ile
              ilişkilendirilecek ve başka bir cihazda giriş yaptığınızda otomatik olarak kullanılacak.
            </li>
            <li>Eğer üye girişi yapmışsanız ekranın en üstünde kullanıcı adınız yazacak.</li>
            <li>Zikirmatik bölümündeki otomatik sayaç bölümü daha anlaşılabilir hale getirildi.</li>
          </ul>
        </small>
      </div>
    </GenericModal>
  )

  return (
    <Navbar color="light" light expand="md">
      {user.username && !user.email && isModalOpened && <EmailSave />}
      {duyuru}

      <Link className="btn geriButonu" to={getBackUrlByPage()}>
        ◀
      </Link>
      <Link className="active font-weight-light mx-0 nav-link navbar-brand px-0 text-center" exact to="/">
        Online Dua {user.username ? ` | ${user.username}` : ''}
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <Link onClick={toggle} className="nav-link" to="/kuran">
              Kur'an
            </Link>
          </NavItem>
          <NavItem>
            <Link onClick={toggle} className="nav-link" to="/dualar">
              Dualar
            </Link>
          </NavItem>
          <NavItem>
            <Link onClick={toggle} className="nav-link" to="/risale">
              Risale
            </Link>
          </NavItem>
          <NavItem>
            <Link onClick={toggle} className="nav-link" to="/grup">
              Grup
            </Link>
          </NavItem>
          <NavItem>
            <Link onClick={toggle} className="nav-link" to="/dashboard">
              Dashboard
            </Link>
          </NavItem>
          <NavItem>
            <Link onClick={toggle} className="nav-link" to="/notepad">
              Not Defteri
            </Link>
          </NavItem>
          <NavItem>
            <a
              onClick={toggle}
              className="nav-link"
              href="mailto:hasenat.dev@gmail.com?subject=OnlineDua.org%20%7C%20Feedback&body=%0D%0ACihaz%3A%0D%0ABrowser%2FTarayici%3A%0D%0A"
            >
              İletişim
            </a>
          </NavItem>
          {user.username ? (
            <NavItem>
              <Link onClick={logout} className="nav-link" to="#">
                Çıkış
              </Link>
            </NavItem>
          ) : (
            <NavItem>
              <Link onClick={toggle} className="nav-link" to="/giris">
                Üyelik
              </Link>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

const mapStateToProps = (state) => {
  return { ...state }
}

export default connect(mapStateToProps)(withRouter(NavComponent))

const getBackUrlByPage = () => {
  const url = window.location.pathname

  if (url.includes('/evradi-kudsiyye/')) {
    return '/evradi-kudsiyye'
  }
  if (url.includes('/cevsen/')) {
    return '/cevsen'
  }
  if (url.includes('/zikir/')) {
    return '/zikirler'
  }
  if (url.includes('/ekd/')) {
    return '/ekd'
  }
  if (url.includes('/hatim/') || url.includes('/hatim-')) {
    return '/hatim'
  }
  if (url.includes('/ekd-meal/')) {
    return '/ekd-meal'
  }
  if (url.includes('/celcelutiye/')) {
    return '/celcelutiye'
  }
  if (url.includes('/grup/')) {
    return '/grup'
  }
  if (url.includes('/buyuk-cevsen/')) {
    return '/buyuk-cevsen'
  }
  if (url.match(/risale\D.+\/\d/)) {
    return url.match(/\D.+\//)[0]
  }
  if (url.includes('/risale/')) {
    return '/risale'
  }

  if (url.includes('/kuran/sayfa') || url.includes('/kuran/sureler') || url.includes('/kuran/cuzler')) {
    return '/kuran'
  }
  if (
    url.includes('/celcelutiye') ||
    url.includes('/cevsen') ||
    url.includes('/zikir') ||
    url.includes('/buyuk-cevsen') ||
    url.includes('/ekd') ||
    url.includes('/diger-dualar') ||
    url.includes('/evradi-kudsiyye')
  ) {
    return '/dualar'
  }

  return '/'
}
