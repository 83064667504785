import React, { useEffect, useState } from 'react'
import PasswordChange from './PasswordChange'

import queryString from 'query-string'
import Axios from 'axios'

export default function PasswordReset(props) {
  const [isValid, setIsValid] = useState(false)
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const { search } = props.location
    const { id } = queryString.parse(search)
    Axios.post(`${process.env.REACT_APP_SERVER}/api/password-token-check`, { id }).then((res) => {
      setIsValid(true)
      setUserData(res.data)
    })
  }, [props.location])

  return (
    <div>
      <h4 className="mb-3">Parola Sıfırlama</h4>
      {isValid ? <PasswordChange email={userData.email} showTitle={false} /> : <p>Bu link geçerli değil.</p>}
    </div>
  )
}
