import Axios from 'axios'
import React, { useState } from 'react'

export default function PasswordChangeRequest() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    Axios.post(`${process.env.REACT_APP_SERVER}/api/password-reset-request`, { email })
      .then(() => {
        setError('Şifre sıfırlama emaili gönderildi!')
        setEmail('')
      })
      .catch(() => {
        setError('Upps, bir sorun ile karşılaşıldı.')
      })
  }

  return (
    <>
      <h3>Şifreyi Sıfırla</h3>

      <form onSubmit={handleSubmit}>
        <input
          className="form-control mt-3"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          type="text"
          name="email"
          id="email"
          required
          placeholder="Email veya Kullanıcı Adı"
        />
        <p>{error}</p>
        <button className="btn btn-success mt-3" type="submit">
          Şifreyi Sıfırla
        </button>
      </form>
    </>
  )
}
