import React from 'react'
import Modal from 'react-responsive-modal'

export default function GenericModal({ children, isOpen, onCloseModal, key, rest }) {
  return (
    <Modal modalId={key} classNames={{ modal: 'bg-white trans-bg rounded' }} open={isOpen} onClose={onCloseModal} center {...rest}>
      {children}
    </Modal>
  )
}
