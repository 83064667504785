import Axios from 'axios'
import React, { useState } from 'react'

const INIT_STATE = { password: '', passwordConf: '' }

export default function PasswordChange({ showTitle = true, fromReset = false, email, history }) {
  const [userInput, setUserInput] = useState(INIT_STATE)
  const [error, setError] = useState('')

  const handleUserInput = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (!userInput.passwordConf || !userInput.password) {
      setError('Lütfen yeni parolanızı girin.')
      return
    }
    if (userInput.passwordConf !== userInput.password) {
      setError('İki parola eşleşmedi.')
      return
    }
    setError('')
    const url = email ? 'password-reset' : 'password-change'
    Axios.post(`${process.env.REACT_APP_SERVER}/api/${url}`, { ...userInput, email })
      .then(() => {
        setError('Sifre degistirildi!')
        setUserInput(INIT_STATE)
        if (email) {
          setTimeout(() => {
            window.location.href = '/giris'
          }, 2000)
        }
      })
      .catch(() => {
        setError('Upps, bir sorun ile karşılaşıldı.')
      })
  }

  return (
    <div>
      {showTitle && <h4 className="mb-3">Parola Degistir</h4>}
      <form onSubmit={handleFormSubmit}>
        <input
          className="form-control"
          name="password"
          onChange={handleUserInput}
          type="password"
          value={userInput.password}
          placeholder="Yeni parola."
          minLength={4}
        />{' '}
        <br />
        <input
          className="form-control"
          name="passwordConf"
          onChange={handleUserInput}
          type="password"
          value={userInput.passwordConf}
          placeholder="Yeni parola tekrari."
          minLength={4}
        />
        <small>{error ? error : ''}</small> <br />
        <button className="btn btn-success" type="submit">
          Kaydet
        </button>
      </form>
    </div>
  )
}
