import Axios from 'axios'
import lockr from 'lockr'
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import GenericModal from '../GenericModal/GenericModal'
import { setLocalStorage } from '../utils'

export default function EmailSave() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [modalOpened, setModalOpened] = useState(lockr.get('email-info'))
  const { addToast } = useToasts()

  const handleSubmit = (e) => {
    e.preventDefault()

    Axios.post(`${process.env.REACT_APP_SERVER}/api/add-email`, { email })
      .then(() => {
        setEmail('')
        handleModal()
        addToast('Email basariyla kaydedildi!', { appearance: 'info', autoDismiss: true })
      })
      .catch(() => {
        setError('Upps, bir sorun ile karşılaşıldı.')
      })
  }

  const handleModal = () => {
    setModalOpened(true)
    setLocalStorage('email-info', true)
  }

  return (
    <GenericModal isOpen={!modalOpened} onCloseModal={handleModal}>
      <h3>Email Kayıt!</h3>
      <p>
        Merhaba, uygulamamıza şifrenizi unuttuğunuz zamanlarda sıfırlayabilme imkanı ekledik ve bunun için email adresinize ihtiyacımız var.
      </p>
      <small>
        <strong>Not: </strong> Bu email adresi sadece bu amaçla kullanılıcak ve size başka bir email gönderilmeyecektir.
      </small>

      <form onSubmit={handleSubmit}>
        <input
          className="form-control mt-3"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          type="email"
          name="email"
          id="email"
          required
          placeholder="Email"
        />
        <p>{error}</p>
        <button className="btn btn-success mt-3" type="submit">
          Kaydet
        </button>
      </form>
    </GenericModal>
  )
}
