import Axios from 'axios'
import jwt from 'jsonwebtoken'
import lockr from 'lockr'
import moment from 'moment'
import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { version } from '../package.json'
import './App.scss'
import Error from './Error/Error'
import Footer from './Footer/Footer'
import Nav from './Nav/Nav.jsx'
import PasswordChangeRequest from './PasswordChange/PasswordChangeRequest'
import PasswordReset from './PasswordChange/PasswordReset'
import { updateUser } from './store/reducers'

const Page = lazy(() => import('./Kuran/Page/Page'))
const Cevsen = lazy(() => import('./Cevsen/Cevsen'))
const Home = lazy(() => import('./Home/Home'))
const Login = lazy(() => import('./Login/Login'))
const Kuran = lazy(() => import('./Kuran/Kuran'))
const Sureler = lazy(() => import('./Kuran/Sureler/Sureler'))
const Cuzler = lazy(() => import('./Kuran/Cuzler/Cuzler'))
const Hesabim = lazy(() => import('./Hesabim/Hesabim'))
const CevsenBablar = lazy(() => import('./Cevsen/CevsenBablari'))
const RisalePage = lazy(() => import('./Risale/RisalePage/RisalePage'))
const Risale = lazy(() => import('./Risale/Risale'))
const BookPage = lazy(() => import('./Risale/BookPage/BookPage'))
const Celce = lazy(() => import('./Celce/Celce'))
const CelcePage = lazy(() => import('./Celce/CelcePage'))
const EvradiKudsiyye = lazy(() => import('./EvradiKudsiyye'))
const EvradiKudsiyePage = lazy(() => import('./EvradiKudsiyye/EvradiKudsiyePage'))
const Dualar = lazy(() => import('./Dualar/Dualar'))
const Zikirler = lazy(() => import('./Zikir/Zikirler'))
const Zikir = lazy(() => import('./Zikir/Zikir'))
const DigerDualar = lazy(() => import('./Dualar/DigerDualar'))
const Dua = lazy(() => import('./Dualar/Dua'))
const Ekd = lazy(() => import('./ekd/EkdDualar'))
const EkdMeal = lazy(() => import('./ekd/ekdMealDualar'))
const EkdPage = lazy(() => import('./ekd/EkdPage'))
const EkdMealPage = lazy(() => import('./ekd/EkdMealPage'))
const HatimSection = lazy(() => import('./Hatim/HatimSection'))
const HatimOlustur = lazy(() => import('./Hatim/HatimOlustur'))
const Hatim = lazy(() => import('./Hatim/Hatim'))
const HatimBul = lazy(() => import('./Hatim/HatimBul'))
const Notepad = lazy(() => import('./Notepad/Notepad'))
const Group = lazy(() => import('./Group/Group'))
const CreateGroup = lazy(() => import('./Group/CreateGroup'))
const GroupView = lazy(() => import('./Group/GroupView'))
const SoruCevap = lazy(() => import('./SoruCevap'))

const BuyukCevsenDuas = lazy(() => import('./BuyukCevsen/BuyukCevsenDuas'))
const BuyukCevsenPage = lazy(() => import('./BuyukCevsen/BuyukCevsenPage'))
class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newVersion: null,
    }
  }

  componentDidMount() {
    let token = lockr.get('token')

    Axios.get(`${process.env.REACT_APP_SERVER}/api/view`).then((res) => {
      let { version: versionFromServer } = res.data
      if (versionFromServer && versionFromServer !== version) {
        this.setState({ newVersion: versionFromServer })
      }
    })

    try {
      var decoded = jwt.decode(token)
      if (decoded) {
        var isValid = moment.unix(decoded.exp).isAfter()
      }
      if (isValid) this.props.dispatch(updateUser(decoded))
    } catch (error) {
      console.log(error)
    }
  }
  render() {
    return (
      <Router>
        <ScrollToTopComponent />
        <div id="bodycik">
          <ToastProvider placement="bottom-left">
            <Nav />
          </ToastProvider>
          <div className="container my-4">
            {this.state.newVersion && (
              <p className="text-danger text-center">
                Uygulamanın yeni sürümü mevcut({this.state.newVersion}), güncellemek için biraz bekledikten sonra uygulamayi kapatip acin
                veya
                <span className="cursor-pointer" onClick={() => window.location.reload()}>
                  {' '}
                  <u>buraya tiklayarak</u>
                </span>{' '}
                yenileyin.
              </p>
            )}
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/cevsen/:no" component={Cevsen} />
                <Route exact path="/cevsen" component={CevsenBablar} />
                <Route exact path="/kuran" component={Kuran} />
                <Route exact path="/giris" component={Login} />
                <Route exact path="/sureler" component={Sureler} />
                <Route exact path="/kuran/sureler" component={Sureler} />
                <Route exact path="/cuzler" component={Cuzler} />
                <Route exact path="/kuran/cuzler" component={Cuzler} />
                <Route exact path="/Risale" component={Risale} />
                <Route exact path="/risale/:kitap/:page" component={RisalePage} />
                <Route exact path="/risale/:kitap" component={BookPage} />
                <Route exact path="/dualar" component={Dualar} />
                <Route exact path="/celcelutiye" component={Celce} />
                <Route exact path="/celcelutiye/:page" component={CelcePage} />
                <Route exact path="/evradi-kudsiyye" component={EvradiKudsiyye} />
                <Route exact path="/evradi-kudsiyye/:page" component={EvradiKudsiyePage} />
                <Route exact path="/zikirler" component={Zikirler} />
                <Route exact path="/diger-dualar" component={DigerDualar} />
                <Route exact path="/ekd" component={Ekd} />
                <Route exact path="/ekd-meal" component={EkdMeal} />
                <Route exact path="/ekd/:id" component={EkdPage} />
                <Route exact path="/ekd-meal/:id" component={EkdMealPage} />
                <Route exact path="/diger-dualar/:id" component={Dua} />
                <Route exact path="/buyuk-cevsen" component={BuyukCevsenDuas} />
                <Route exact path="/buyuk-cevsen/:id" component={BuyukCevsenPage} />

                <Route path="/zikir/:id" component={Zikir} />
                <Route path="/hatim" exact component={HatimSection} />
                <Route path="/hatim/:shortId" exact component={Hatim} />
                <Route path="/hatim-olustur" exact component={HatimOlustur} />
                <Route path="/hatim-bul" exact component={HatimBul} />
                <Route path="/notepad" exact component={Notepad} />
                <Route path="/sikca-sorulan-sorular" exact component={SoruCevap} />
                <ToastProvider placement="bottom-left">
                  <Route exact path="/dashboard" component={Hesabim} />
                  <Route path="/sayfa/:no" component={Page} />
                  <Route exact path="/kuran/sayfa/:no" component={Page} />
                  <Route path="/grup" exact component={Group} />
                  <Route path="/grup/:id" exact component={GroupView} />
                  <Route path="/grup-olustur" exact component={CreateGroup} />
                  <Route path="/parola-reset-istek" exact component={PasswordChangeRequest} />
                  <Route path="/parola-reset" exact component={PasswordReset} />
                </ToastProvider>
                <Route component={Error} />
              </Switch>
            </Suspense>
          </div>
          <ToastProvider placement="bottom-left">
            <Footer />
          </ToastProvider>
        </div>
      </Router>
    )
  }
}
const mapStateToProps = (state) => {
  return { ...state }
}
export default connect(mapStateToProps)(App)

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return null
  }
}

const ScrollToTopComponent = withRouter(ScrollToTop)
