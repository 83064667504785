import jwt from 'jsonwebtoken'
import lockr from 'lockr'
import moment from 'moment'
import { arabFontStyleDefault, arabFontStyleName } from '../utils'

const reducer = (
  state = {
    user: {},
    marked: {},
    bookmarks: {},
    font: 'textBased',
    fontVersion: lockr.get('fontVersion') || 'ver-2',
    mealOption: lockr.get('mealOption') || 'mealsiz',
    userData: [],
    saved: [],
    arabFontStyle: lockr.get(arabFontStyleName) || arabFontStyleDefault,
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_MARKED':
      return { ...state, marked: action.data }
    case 'UPDATE_BOOKMARK':
      return { ...state, bookmarks: { ...state.bookmarks, ...action.data } }
    case 'UPDATE_USERDATA':
      return { ...state, userData: action.data }
    case 'UPDATE_USER':
      return { ...state, user: action.data }
    case 'GET_USER':
      let token = lockr.get('token')
      try {
        var decoded = jwt.decode(token)
        if (decoded) {
          var exp = moment.unix(decoded.exp).isAfter()
          if (exp) {
            return { ...state, user: decoded }
          }
        }
      } catch (error) {
        console.log(error)
      }

      break
    case 'UPDATE_FONT':
      return { ...state, font: action.data }
    case 'UPDATE_FONT_VERSION':
      return { ...state, fontVersion: action.data }
    case 'UPDATE_ARAB_FONT_STYLE':
      return { ...state, arabFontStyle: action.data }
    case 'UPDATE_MEAL_OPTION':
      return { ...state, mealOption: action.data }
    case 'UPDATE_SAVED':
      return { ...state, saved: action.data }
    default:
      return state
  }
}

//actions
export const updateUser = (data) => {
  return {
    type: 'UPDATE_USER',
    data,
  }
}
export const updateUserData = (data) => {
  return {
    type: 'UPDATE_USERDATA',
    data,
  }
}
export const getUser = () => {
  return {
    type: 'GET_USER',
  }
}

export const updateBookmark = (data) => {
  return {
    type: 'UPDATE_BOOKMARK',
    data,
  }
}

export const updateFont = (data) => {
  return {
    type: 'UPDATE_FONT',
    data,
  }
}
export const updateFontVersion = (data) => {
  return {
    type: 'UPDATE_FONT_VERSION',
    data,
  }
}
export const updateMealOption = (data) => {
  return {
    type: 'UPDATE_MEAL_OPTION',
    data,
  }
}

export const updateSaved = (data) => {
  return {
    type: 'UPDATE_SAVED',
    data,
  }
}

export const updateArabFontStyle = (data) => {
  return {
    type: 'UPDATE_ARAB_FONT_STYLE',
    data,
  }
}

export default reducer
